import React from "react";
import { Menu } from "antd";

import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { logOutAction } from "../../redux/reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import { IoHomeOutline, IoLogOut, IoCard, IoLanguage } from "react-icons/io5";
import { SettingOutlined } from "@ant-design/icons";
import {
  MdAccountBalance,
  MdAlignHorizontalCenter,
  MdCampaign,
  MdEvent,
  MdEventAvailable,
  MdOutlineProductionQuantityLimits,
  MdVerifiedUser,
} from "react-icons/md";
import CustomButton from "../CustomButton";
import { changeLanguageAction } from "../../redux/reducers/settingsReducer";
import { RiPagesLine } from "react-icons/ri";
import { SiAmazonsimpleemailservice } from "react-icons/si";
import { CgTemplate } from "react-icons/cg";
import { FaUsers } from "react-icons/fa";

const SuperUserSide = ({ toggleSideBar }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const items = [
    {
      key: "home",
      icon: <IoHomeOutline size={20} />,
      label: <Link to="/">{intl.formatMessage({ id: "home" })}</Link>,
    },
    {
      key: "customers",
      icon: <FaUsers size={20} />,
      label: (
        <Link to="/customers">{intl.formatMessage({ id: "customers" })}</Link>
      ),
    },
    // {
    //   key: "products",
    //   icon: <MdOutlineProductionQuantityLimits size={20} />,
    //   label: (
    //     <Link to='/products'>{intl.formatMessage({ id: "products" })}</Link>
    //   ),
    // },
    // {
    //   label: (
    //     <Link to='/email-settings'>
    //       {intl.formatMessage({ id: "emailSettings" })}
    //     </Link>
    //   ),
    //   key: "emailSettings",
    //   icon: <MdAlignHorizontalCenter size={20} />,
    // },
    {
      label: <Link to="/account">{intl.formatMessage({ id: "account" })}</Link>,
      key: "account",
      icon: <MdVerifiedUser size={20} />,
    },
    {
      label: (
        <Link to="/campaigns">
          {intl.formatMessage({ id: "autoCampaigns" })}
        </Link>
      ),
      key: "campaigns",
      icon: <MdCampaign size={20} />,
    },
    {
      label: (
        <Link to="/custom-campaigns">
          {intl.formatMessage({ id: "customCampaigns" })}
        </Link>
      ),
      key: "customCampaigns",
      icon: <SiAmazonsimpleemailservice size={20} />,
    },
    {
      label: (
        <Link to="/templates">{intl.formatMessage({ id: "templates" })}</Link>
      ),
      key: "templates",
      icon: <CgTemplate size={20} />,
    },
    // {
    //   label: <Link to='/tracker'>{intl.formatMessage({ id: "track" })}</Link>,
    //   key: "tracker",
    //   icon: <MdEvent size={20} />,
    // },
    // {
    //   label: (
    //     <Link to='/subscription'>
    //       {intl.formatMessage({ id: "subscription" })}
    //     </Link>
    //   ),
    //   key: "subscription",
    //   icon: <IoCard size={20} />,
    // },
    {
      label: (
        <CustomButton
          onClick={() => dispatch(changeLanguageAction())}
          text={intl.formatMessage({ id: "oppLanguage" })}
          type="text"
        />
      ),
      key: "language",
      icon: <IoLanguage size={20} />,
    },
    {
      label: (
        <CustomButton
          onClick={() => dispatch(logOutAction())}
          text={intl.formatMessage({ id: "signout" })}
          type="text"
        />
      ),
      key: "signOut",
      icon: <IoLogOut size={20} />,
    },
  ];
  const adminItems = [
    {
      label: <Link to="/stores">{intl.formatMessage({ id: "stores" })}</Link>,
      key: "admin",
      icon: <MdAccountBalance size={20} />,
    },
    {
      label: (
        <Link to="/test-events">{intl.formatMessage({ id: "events" })}</Link>
      ),
      key: "events",
      icon: <MdEventAvailable size={20} />,
    },
    {
      label: (
        <Link to="/templates-admin">
          {intl.formatMessage({ id: "templates" })}
        </Link>
      ),
      key: "templates",
      icon: <RiPagesLine size={20} />,
    },
    {
      label: (
        <Link to="/default-templates-admin">
          {intl.formatMessage({ id: "defaultTemplates" })}
        </Link>
      ),
      key: "default-templates-admin",
      icon: <RiPagesLine size={20} />,
    },
    {
      label: (
        <CustomButton
          onClick={() => dispatch(changeLanguageAction())}
          text={intl.formatMessage({ id: "oppLanguage" })}
          type="text"
        />
      ),
      key: "language",
      icon: <IoLanguage size={20} />,
    },
    {
      label: (
        <CustomButton
          onClick={() => dispatch(logOutAction())}
          text={intl.formatMessage({ id: "signout" })}
          type="text"
        />
      ),
      key: "signOut",
      icon: <IoLogOut size={20} />,
    },
  ];
  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["1"]}
      items={user?.role === "admin" ? adminItems : items}
      onClick={() => {
        toggleSideBar();
      }}
    ></Menu>
  );
};

export default SuperUserSide;
