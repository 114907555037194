import { Form, Typography, Select, Input, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import openNotification from "../../../Components/Notifications";
import { editCampaign } from "../../../API/fetch";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import EmojiInput from "../../../Components/EmojiInput/EmojiInput";

const { Option } = Select;
const { Title } = Typography;
function EditCampaign() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { campaigns, templates } = useSelector((state) => state.store);

  useEffect(() => {
    if (id) {
      const campaign = campaigns.find((temp) => temp._id === id);
      if (campaign) {
        form.setFieldsValue({
          name: campaign.subject,
          campaignTime: dayjs(campaign.date),
          target: campaign.target,
          template: campaign.templateId,
          subject: campaign.subject,
        });
        setInputValue(campaign.subject);
      }
    }
  }, [id]);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const date = values.campaignTime.toDate();
      const data = {
        name: values.subject,
        date,
        target: values.target,
        template: values.template,
        subject: values.subject,
      };
      const res = await editCampaign(id, data);
      setLoading(false);
      openNotification({
        title: "Success",
        description: "Template created successfully",
        type: "success",
        rtl: true,
      });
      navigate("/custom-campaigns");
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "editCampagn" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className="light-shadow"
      >
        {/* Create a form that has name, type, emails for the campaign */}
        <Form
          form={form}
          layout="vertical"
          name="new-campaign"
          onFinish={onFinish}
          initialValues={{
            type: "email",
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: "campaignName" })}
            name="name"
            style={{ display: "none" }}
          >
            <Input />
          </Form.Item>
          <EmojiInput
            form={form}
            name="subject"
            label={intl.formatMessage({ id: "subjectEmail" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
            setInputValue={setInputValue}
            inputValue={inputValue}
            showCounter={true}
          />

          <Form.Item
            label={intl.formatMessage({ id: "campaignTime" })}
            name="campaignTime"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          {/* target */}
          <Form.Item
            label={intl.formatMessage({ id: "target" })}
            name="target"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            <Select className="my-font">
              <Option value="SALLA" className="my-font">
                {intl.formatMessage({ id: "sallaClients" })}
              </Option>
              <Option value="customEXCEL" disabled className="my-font">
                {intl.formatMessage({ id: "customExcel" })}
              </Option>
              <Option value="BOTH" disabled className="my-font">
                {intl.formatMessage({ id: "both" })}
              </Option>
            </Select>
          </Form.Item>
          {/* Template */}
          <Form.Item
            label={intl.formatMessage({ id: "template" })}
            name="template"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            {templates.length === 0 ? (
              <CustomButton
                text={intl.formatMessage({ id: "createTemplate" })}
                type="primary"
                onClick={() => {
                  navigate("/new-template");
                }}
                style={{
                  marginBottom: 10,
                }}
              />
            ) : null}
            <Select
              className="my-font"
              disabled={templates?.length === 0}
              placeholder={
                templates?.length === 0
                  ? intl.formatMessage({ id: "noTemplate" })
                  : ""
              }
            >
              {templates.map((template) => (
                <Option
                  value={template._id}
                  key={template._id}
                  className="my-font"
                >
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <CustomButton
              text={intl.formatMessage({ id: "save" })}
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default EditCampaign;
