import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as XLSX from "xlsx";
import { Button, Dropdown, Space, Spin, Table, Typography, Upload } from "antd";
//API and Redux
import {
  exportCustomerToExcel,
  getStoreCustomers,
  getStoreGroups,
  importCustomerFromExcel,
} from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setStoreCustomersAction,
  setStoreGroupsAction,
} from "../../redux/reducers/storeReducer";
//Components
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
//helper data
import COLORS from "../../Style/colors";
import {
  AiOutlineUpload,
  AiOutlineDownload,
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
} from "react-icons/ai";

const { Title, Text } = Typography;

function Customers() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { store, groups } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [uploadExcel, setUploadExcel] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(50);
  const [customers, setCustomers] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (store?._id) {
      getAllCustomers();
      getAllGroups();
    }
  }, [store?._id]);

  const getAllGroups = async () => {
    try {
      const res = await getStoreGroups();
      if (res?.data?.groups) {
        dispatch(setStoreGroupsAction(res?.data?.groups));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomers = async (p, l) => {
    try {
      setLoading(true);
      let pg = p || page;
      let lg = l || length;
      const res = await getStoreCustomers(pg, lg);
      let allCity = [];
      if (res?.data?.customers) {
        setCustomers(res?.data?.customers);
        dispatch(setStoreCustomersAction(res?.data?.customers));
        setTotal(res?.data?.total);
        res?.data?.customers.forEach((c) => {
          if (c?.city) {
            if (!cities.includes(c?.city)) {
              allCity.push(c?.city);
            }
          }
        });
        setCities(allCity);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadExcel(true);
      const res = await exportCustomerToExcel();
      if (res?.data?.customers) {
        const selectedFields = res.data.customers.map(
          ({ name, phone, email }) => ({
            name,
            phone,
            email,
          })
        );
        const worksheet = XLSX.utils.json_to_sheet(selectedFields);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

        XLSX.writeFile(workbook, "CustomersData.xlsx");
        setDownloadExcel(false);
        openNotification({
          title: intl.formatMessage({ id: "uploadCustomersSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setDownloadExcel(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const handleUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const customers = XLSX.utils.sheet_to_json(sheet);
        try {
          setUploadExcel(true);
          const res = await importCustomerFromExcel({ customers });
          if (res.data.status === "success") {
            openNotification({
              title: `${intl.formatMessage({
                id: "AddedCustomer",
              })} ${res.data.total} ${intl.formatMessage({
                id: "newCustomerSuccess",
              })} `,
              type: "success",
              rtl,
            });
            await getAllCustomers();
            setUploadExcel(false);
          }
        } catch (error) {
          setUploadExcel(false);
          openNotification({
            title: intl.formatMessage({ id: "error" }),
            type: "error",
            description: "something went wrong",
            rtl,
          });
        }
      };
      reader.readAsBinaryString(file);
    }
    return false;
  };

  const props = {
    beforeUpload: (file) => handleUpload(file),
    accept: ".xlsx, .xls",
  };

  const downloadTemplate = () => {
    const headers = [["name", "email", "phone"]];
    const worksheet = XLSX.utils.aoa_to_sheet(headers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "CustomerTemplate.xlsx");
  };

  const items = [
    {
      key: "1",
      label: (
        <Upload showUploadList={false} {...props}>
          <Button icon={<AiOutlineCloudUpload />} loading={uploadExcel}>
            {intl.formatMessage({ id: "uploadNow" })}
          </Button>
        </Upload>
      ),
    },
    {
      key: "2",
      label: (
        <Button icon={<AiOutlineCloudDownload />} onClick={downloadTemplate}>
          {intl.formatMessage({ id: "downloadTemplate" })}
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },
    {
      title: intl.formatMessage({ id: "phone" }),
      dataIndex: "phone",
      key: "phone",
    },
    // gender
    {
      title: intl.formatMessage({ id: "gender" }),
      dataIndex: "gender",
      key: "gender",
      filters: [
        {
          text: intl.formatMessage({ id: "male" }),
          value: "male",
        },
        {
          text: intl.formatMessage({ id: "female" }),
          value: "female",
        },
        {
          text: intl.formatMessage({ id: "blank" }),
          value: "",
        },
      ],
      onFilter: (value, record) => {
        if (value === "") {
          return record?.gender !== "male" && record?.gender !== "female";
        }
        return record?.gender === value;
      },
    },
    // city
    {
      title: intl.formatMessage({ id: "city" }),
      dataIndex: "city",
      key: "city",
      filters: cities.map((c) => {
        return {
          text: c,
          value: c,
        };
      }),
      onFilter: (value, record) => record?.city === value,
    },
    {
      title: intl.formatMessage({ id: "group" }),
      key: "otherGroupIds",
      dataIndex: "otherGroupIds",
      render: (groupsArray, record) => {
        return (
          <div>
            {groupsArray?.map((g, i) => {
              const found = groups?.find((gr) => `${gr?.groupId}` === `${g}`);
              return (
                <Text key={`${g}`} style={{ display: "block" }}>
                  {found?.name}
                </Text>
              );
            })}
          </div>
        );
      },
      filters: groups?.map((g) => {
        return {
          text: g?.name,
          value: g?.groupId,
        };
      }),
      onFilter: (value, record) => {
        return record?.otherGroupIds?.includes(value);
      },
    },
  ];

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "customers" })}</Title>
        <div style={{ display: "flex", gap: "10px" }}>
          <Space wrap>
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button icon={<AiOutlineUpload />}>
                {intl.formatMessage({ id: "uploadClientToExcel" })}
              </Button>
            </Dropdown>
          </Space>
          <CustomButton
            text={intl.formatMessage({ id: "downloadExcel" })}
            onClick={handleDownload}
            className={`btnRegister btn-text`}
            loading={downloadExcel}
            type="primary"
            icon={<AiOutlineDownload />}
          />
        </div>
      </div>

      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className="light-shadow"
        >
          <Table
            dataSource={customers?.map((t) => {
              return {
                ...t,
                key: t?._id,
              };
            })}
            columns={columns}
            pagination={{
              total,
              pageSize: length,
              onChange: (page, length) => {
                setPage(page);
                setLength(length);
                getAllCustomers(page, length);
              },
            }}
            scroll={{ x: 800 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Customers;
