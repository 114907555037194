import { Alert, Button, Image, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import { createTemplate, getTemplateLibrary } from "../../../API/fetch";
import { adminTemplateData } from "../../../assets/images/templatesImages/index";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PLAN_NAMES } from "../../../Types";

let SALA_URL = "https://s.salla.sa/apps/install/1005716042?upgrade=1";
const { Title } = Typography;
function TemplatesLibrary() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isFreeUser, setIsFreeUser] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getLibrary();
  }, []);

  useEffect(() => {
    if (user && user.stores[0].subscriptions[0].plan === PLAN_NAMES.FREE) {
      setIsFreeUser(true);
    } else {
      setIsFreeUser(false);
    }
  }, [user]);

  const getLibrary = async () => {
    try {
      setLoading(true);
      const res = await getTemplateLibrary();

      if (res?.data?.templates) {
        let formatted = res?.data?.templates.map((temp) => {
          const image = adminTemplateData.find((one) => one.id === temp._id);
          if (image) {
            return {
              ...temp,
              image: image.image,
            };
          } else {
            return {
              ...temp,
              image: adminTemplateData[0].image,
            };
          }
        });
        setTemplates(formatted);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const copyTemplate = async (id) => {
    setLoading(true);
    try {
      if (isFreeUser) {
        setShowAlert(true);
        setLoading(false);
        return;
      }
      // get the template;
      const template = templates.find((temp) => temp._id === id);
      const { design, html, variables } = template;
      const myData = {
        name: `${template.name} - Copy`,
        html,
        design,
        variables,
      };
      const res = await createTemplate(myData);
      // navigate to the template
      if (res?.data?.template?._id) {
        navigate(`/templates/`);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="tables-card-container">
      {showAlert ? (
        <div
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 91100,
            textAlign: "center",
            left: 0,
            top: 10,
          }}
        >
          <Alert
            closable
            onClose={() => setShowAlert(false)}
            className="my-font"
            message={intl.formatMessage({ id: "templateWarning" })}
            description={
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  style={{
                    color: "white",
                    backgroundColor: COLORS.primary,
                    padding: "5px 10px",
                    borderRadius: 5,
                  }}
                  href={SALA_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {intl.formatMessage({
                    id: "upgradeNow",
                  })}
                </a>
                <Button className="my-font" onClick={() => setShowAlert(false)}>
                  {intl.formatMessage({
                    id: "later",
                  })}
                </Button>
              </div>
            }
            type={"warning"}
          ></Alert>
        </div>
      ) : null}
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "templateLibrary" })}</Title>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
            minHeight: "90vh",
            flexDirection: "row",
            justifyContent: "space-between",
            display: "flex",
            flexWrap: "wrap",
          }}
          className="light-shadow"
        >
          <Image.PreviewGroup
            preview={{
              onChange: (current, prev) =>
                console.log(`current index: ${current}, prev index: ${prev}`),
            }}
          >
            {templates.map((temp) => {
              return (
                <div
                  key={temp._id}
                  style={{
                    marginRight: 5,
                    marginBottom: 30,
                    borderWidth: 1,
                    borderColor: COLORS.gray,
                  }}
                >
                  <Image
                    width={250}
                    src={temp.image}
                    style={{ cursor: "pointer", objectFit: "cover" }}
                  />
                  <div
                    style={{
                      padding: 5,
                      backgroundColor: `${COLORS.primary}`,
                      borderBottomRightRadius: 10,
                      borderBottomLeftRadius: 10,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Title
                      level={5}
                      style={{
                        color: COLORS.white,
                        paddingBottom: 10,
                      }}
                    >
                      {temp.name}
                    </Title>
                    <CustomButton
                      text={intl.formatMessage({ id: "copy" })}
                      onClick={() => {
                        copyTemplate(temp._id);
                      }}
                      // disabled={isFreeUser}
                      type="outline"
                      style={{
                        color: COLORS.primary,
                        backgroundColor: COLORS.white,
                        borderRadius: 5,
                        fontWhite: "bold",
                        // cursor : isFreeUser ? "not-allowed" :"pointer"
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </Image.PreviewGroup>
        </div>
      </Spin>
    </div>
  );
}

export default TemplatesLibrary;
