import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { getStoreCampaigns } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Table, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { setStoreCampaignsAction } from "../../redux/reducers/storeReducer";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { PLAN_NAMES } from "../../Types";

const { Title, Text } = Typography;

function CustomCampaign() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { store, campaigns } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [canAddCampign, setCanAddCampign] = useState(true);
  useEffect(() => {
    if (store?._id) {
      getCampaigns();
    }
  }, [store?._id]);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await getStoreCampaigns();
      if (res?.data?.campaigns) {
        dispatch(setStoreCampaignsAction(res?.data?.campaigns));
        setLoading(false);
        // const limit = store?.monthlyCampaignLimit;
        // count all campaigns in the current month
        const currentMonth = dayjs().month();
        const currentYear = dayjs().year();
        let counter = 0;
        res?.data?.campaigns.forEach((c) => {
          if (
            dayjs(c.createdAt).month() === currentMonth &&
            dayjs(c.createdAt).year() === currentYear
          ) {
            counter++;
          }
        });

        // openNotification({
        //   title: intl.formatMessage({ id: "success" }),
        //   type: "success",
        //   description: intl.formatMessage({ id: "successUpdate" }),
        //   rtl,
        // });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  useEffect(() => {
    let all = [];
    if (campaigns?.length) {
      all = campaigns.map((c) => {
        let failed = 0;
        let delivered = 0;
        let sent = 0;
        let open = 0;
        let click = 0;
        c?.depthEmails?.forEach((one) => {
          if (one?.clickedCount > 0) {
            click++;
          } else if (one?.openedCount > 0) {
            open++;
          } else if (one?.deliveredCount > 0) {
            delivered++;
          }
          if (one?.sesMessageId) {
            sent += 1;
          } else {
            failed += 1;
          }
        });

        return {
          key: c?._id,
          sent,
          open,
          click,
          failed,
          delivered,
          ...c,
        };
      });
    }
    campaigns?.map((c) => {
      return {
        ...c,
        key: c?._id,
      };
    });
    setCampaignData(all);
  }, [campaigns]);

  const columns = [
    {
      title: intl.formatMessage({ id: "subjectEmail" }),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: intl.formatMessage({ id: "sent" }),
      dataIndex: "sent",
      key: "sent",
    },
    // {
    //   title: intl.formatMessage({ id: "delivered" }),
    //   dataIndex: "delivered",
    //   key: "delivered",
    // },
    {
      title: intl.formatMessage({ id: "open" }),
      dataIndex: "open",
      key: "open",
    },
    {
      title: intl.formatMessage({ id: "clicked" }),
      dataIndex: "click",
      key: "click",
    },
    {
      title: intl.formatMessage({ id: "failed" }),
      dataIndex: "failed",
      key: "failed",
    },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      render: (text) => <Text>{new Date(text).toDateString()}</Text>,
    },
    {
      title: intl.formatMessage({ id: "edit" }),
      key: "action",
      render: (text, record) => (
        <CustomButton
          text={intl.formatMessage({ id: "edit" })}
          onClick={() => {
            navigate(`/campaigns/${record._id}`);
          }}
          type="link"
        />
      ),
    },
  ];

  useEffect(() => {
    if (
      user?.stores?.[0]?.domain?.includes("salla.sa") &&
      store?.subscriptions?.[0]?.plan === PLAN_NAMES.FREE
    ) {
      setCanAddCampign(false);
    } else {
      setCanAddCampign(true);
    }
  }, [user, store]);

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "customCampaigns" })}</Title>
        <CustomButton
          text={intl.formatMessage({ id: "addCampaign" })}
          onClick={() => {
            navigate("/new-campaign");
          }}
          type="primary"
          disabled={!canAddCampign}
        />
      </div>

      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className="light-shadow"
        >
          <Table dataSource={campaignData} columns={columns} />
        </div>
      </Spin>
    </div>
  );
}

export default CustomCampaign;
