import { Button, Form, Input } from "antd";
import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import COLORS from "../../Style/colors";

function EmojiInput({
  form,
  setInputValue,
  inputValue,
  name,
  rules,
  label,
  disabled = false,
  showCounter = false,
  handleClick,
}) {
  const intl = useIntl();
  const emojiPickerRef = useRef(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const onEmojiClick = (emojiData) => {
    const emoji = emojiData.emoji;
    const updatedValue = inputValue + emoji;
    setInputValue(updatedValue);
    form.setFieldsValue({ [name]: updatedValue });
    setShowEmojiPicker(false);
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);
  return (
    <div style={{ position: "relative" }}>
      <Form.Item label={label} name={name} rules={rules}>
        <Input
          value={inputValue}
          disabled={disabled}
          style={{cursor:"pointer"}}
          onChange={(e) => setInputValue(e.target.value)}
          suffix={
            <Button
              disabled={disabled}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              size="small"
            >
              😊
            </Button>
          }
        />
      </Form.Item>
      {showEmojiPicker ? (
        <div
          ref={emojiPickerRef}
          style={{
            position: "absolute",
            top: 70,
            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #d9d9d9",
            borderRadius: "5px",
          }}
        >
          <EmojiPicker
            onEmojiClick={onEmojiClick}
            showPreview={false}
            disabled={disabled}
          />
        </div>
      ) : null}
      {inputValue?.length > 0 && showCounter ? (
        <span
          style={{
            position: "absolute",
            top: 64,
            color: COLORS.primary,
          }}
        >
          {intl.formatMessage({ id: "caractereCount" })} : {inputValue.length}
        </span>
      ) : null}
      {handleClick && disabled ? (
        <div
          onClick={handleClick}
          style={{
            position: "absolute",
            top: 30,
            right: 0,
            height: "50%",
            width: "100%",
            cursor:"pointer"
          }}
        />
      ) : null}
    </div>
  );
}

export default EmojiInput;
