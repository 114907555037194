import { Form, Typography, Select, Input, DatePicker, Popconfirm } from "antd";
import React, { useState } from "react";
import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import openNotification from "../../../Components/Notifications";
import { createCampaign } from "../../../API/fetch";
import dayjs from "dayjs";
import EmojiInput from "../../../Components/EmojiInput/EmojiInput";

const { Option } = Select;
const { Title } = Typography;
function NewCampaign() {
  const intl = useIntl();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { templates, groups, store, campaigns, customers } = useSelector(
    (state) => state.store
  );
  const onFinish = async (values) => {
    try {
      if (store?.subscriptions?.[0]?.plan === "free") {
        openNotification({
          title: "هذه الخدمة غير مترفرة لديك",
          description: "قم بترقية اشتركك لتتمكن من إرسال الحملات",
          type: "error",
          rtl: true,
        });
        return;
      }
      // const limit = store?.monthlyCampaignLimit;
      // count all campaigns in the current month
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      let counter = 0;
      campaigns.forEach((c) => {
        if (
          dayjs(c.createdAt).month() === currentMonth &&
          dayjs(c.createdAt).year() === currentYear
        ) {
          counter++;
        }
      });
      // if (counter >= limit) {
      //   openNotification({
      //     title: "Error",
      //     description: `You have reached the limit of ${limit} campaigns for this month`,
      //     type: "error",
      //     rtl: true,
      //   });
      //   return;
      // }
      setLoading(true);
      const date = values.campaignTime.toDate();
      const data = {
        name: values.subject,
        date,
        targets: values.targets,
        template: values.template,
        subject: values.subject,
      };
      const res = await createCampaign(data);
      setLoading(false);
      openNotification({
        title: "Success",
        description: "Template created successfully",
        type: "success",
        rtl: true,
      });
      navigate("/custom-campaigns");
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "newCampaign" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className="light-shadow"
      >
        {/* Create a form that has name, type, emails for the campaign */}
        <Form
          form={form}
          layout="vertical"
          name="new-campaign"
          onFinish={onFinish}
          initialValues={{
            type: "email",
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: "campaignName" })}
            name="name"
            style={{ display: "none" }}
          >
            <Input />
          </Form.Item>
          <EmojiInput
            form={form}
            name="subject"
            label={intl.formatMessage({ id: "subjectEmail" })}
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
            setInputValue={setInputValue}
            inputValue={inputValue}
            showCounter={true}
          />
          <Form.Item
            label={intl.formatMessage({ id: "campaignTime" })}
            name="campaignTime"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>
          {/* target */}
          <Form.Item
            label={intl.formatMessage({ id: "target" })}
            name="targets"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            <Select className="my-font" mode="multiple">
              <Option value="SALLA" className="my-font">
                {intl.formatMessage({ id: "sallaClients" })}
              </Option>
              {groups.map((group) => {
                return (
                  <Option value={group._id} key={group._id} className="my-font">
                    {group.name}
                  </Option>
                );
              })}
              <Option value="customEXCEL" disabled className="my-font">
                {intl.formatMessage({ id: "customExcel" })}
              </Option>
              <Option value="BOTH" disabled className="my-font">
                {intl.formatMessage({ id: "both" })}
              </Option>
            </Select>
          </Form.Item>

          {/* Template */}
          <Form.Item
            label={intl.formatMessage({ id: "template" })}
            name="template"
            rules={[
              {
                required: true,
                message: intl.formatMessage({ id: "required" }),
              },
            ]}
          >
            {templates.length === 0 ? (
              <CustomButton
                text={intl.formatMessage({ id: "createTemplate" })}
                type="primary"
                onClick={() => {
                  navigate("/new-template");
                }}
                style={{
                  marginBottom: 10,
                }}
              />
            ) : null}
            <Select
              className="my-font"
              disabled={templates?.length === 0}
              placeholder={
                templates?.length === 0
                  ? intl.formatMessage({ id: "noTemplate" })
                  : ""
              }
            >
              {templates.map((template) => {
                return (
                  <Option
                    value={template._id}
                    key={template._id}
                    className="my-font"
                  >
                    {template.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            {store?.remainingEmails < customers?.length ? (
              <Popconfirm
                title={`رصيدك المتبقي لا يكفي، هل تريد المتابعة ؟`}
                description={`رصيدك الحالي ${store?.remainingEmails} وعدد العملاء ${customers?.length} سيتم الإرسال لعملاء عشوائيين بعدد الايميلات المتبقية`}
                onConfirm={() => {
                  form.submit();
                }}
                onCancel={() => true}
                okText="إرسال"
                cancelText="تراجع"
              >
                <CustomButton
                  text={intl.formatMessage({ id: "send" })}
                  type="primary"
                  loading={loading}
                />
              </Popconfirm>
            ) : (
              <CustomButton
                text={intl.formatMessage({ id: "send" })}
                type="primary"
                htmlType="submit"
                loading={loading}
                // disabled={store?.subscriptions?.[0]?.plan === "free"}
              />
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default NewCampaign;
