import React, { useEffect, useState } from "react";
import EmojiInput from "../../../Components/EmojiInput/EmojiInput";

function TitleInput({
  form,
  name,
  rules,
  label,
  disabled,
  campaigns,
  handleClick,
}) {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (campaigns) {
      if (name === "welcomeEmail_Subject") {
        setInputValue(form.getFieldValue("welcomeEmail_Subject"));
      } else if (name === "mostViewedProductEmail_Subject") {
        setInputValue(form.getFieldValue("mostViewedProductEmail_Subject"));
      } else if (name === "addedToCartEmail_Subject") {
        setInputValue(form.getFieldValue("addedToCartEmail_Subject"));
      } else if (name === "abandonedCartEmail_Subject") {
        setInputValue(form.getFieldValue("abandonedCartEmail_Subject"));
      } else {
        setInputValue("");
      }
    }
  }, [name, form, campaigns]);

  return (
    <EmojiInput
      form={form}
      name={name}
      label={label}
      rules={rules}
      setInputValue={setInputValue}
      inputValue={inputValue}
      disabled={disabled}
      showCounter={true}
      handleClick={handleClick}
    />
  );
}

export default TitleInput;
