export const TAG_STAGES_COLOR ={
    // color gray
    initiated: 'gray',
    completed: 'orange',
    addedToCart: 'purple',
    abandonedCart: 'red',
}


export const PLAN_NAMES = {
    FREE: "free",
    BASIC: "basic",
    PREMIUM: "premium",
    ENTERPRISE: "enterprise",
  };